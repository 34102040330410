<template>
  <div class="audit-report">
    <LogoTitle msg="审计报告"></LogoTitle>
    <FileList></FileList>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
</style>